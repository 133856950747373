import React from "react";
import unstop from '../assets/unstop.jpg';
import motorq from '../assets/motorq.png';
import ScrollFloat from "../components/ScrollFloat";

const Sponsors = () => {
  return (
    <div className="flex flex-col items-center bg-blue text-white py-10" id="sponsor">
      <h2 className="text-3xl font-bold mb-6">  
        <ScrollFloat
          animationDuration={1}
          ease='back.inOut(2)'
          scrollStart='center bottom+=50%'
          scrollEnd='bottom bottom-=40%'
          stagger={0.03}
          className="text-10xl font-bold glitch"
        >
          Sponsors
        </ScrollFloat>
      </h2>

      <div className="flex flex-col items-center space-y-6">
       
        <img 
          src={motorq} 
          alt="Title Sponsor - Motorq" 
          className="w-[150px] md:w-[180px] lg:w-[200px] rounded-lg shadow-lg" 
        />
        <h2 className="text-lg font-semibold">Title Sponsor - Motorq</h2>

        
        <img 
          src={unstop} 
          alt="Platform Sponsor - Unstop" 
          className="w-[180px] md:w-[200px] lg:w-[220px] rounded-lg shadow-lg" 
        />
        <h2 className="text-lg font-semibold">Platform Sponsor - Unstop</h2>
      </div>
    </div>
  );
};

export default Sponsors;
