import React from "react";
import { BiMailSend } from "react-icons/bi";
import { AiOutlinePhone } from "react-icons/ai";
import ScrollFloat from "../components/ScrollFloat";


const Contact = () => {

  return (
    <div className="bg-gray-900 p-5 text-center" id="contact">
      <div className="p-3">
        <h3 className="mb-4 text-4xl font-bold tracking-tight text-white">
        <ScrollFloat
  animationDuration={1}
  ease='back.inOut(2)'
  scrollStart='center bottom+=50%'
  scrollEnd='bottom bottom-=40%'
  stagger={0.03}
    className="text-10xl font-bold"
>
    Contact Us
      
</ScrollFloat>
        </h3>
      </div>
      <div className="flex justify-center">
        <div className="w-full md:max-w-screen-md p-6 rounded-lg shadow-lg bg-gray-800 text-white">
          <div className="space-y-4">
            <div className="flex items-center space-x-4">
              <BiMailSend size={32} className="text-primary-300" />
              <div className="text-xl font-semibold">Email:</div>
              <a href="mailto:your@email.com" className="text-lg text-gray-300">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;acmceg2019@
                gmail.com | anshbomb16@gmail.com
              </a>
            </div>
            <br />
            <div className="flex items-center space-x-4">
              <AiOutlinePhone size={32} className="text-primary-300" />
              <div className="text-xl font-semibold">Phone:</div>
              <div className="text-lg text-gray-300 pl-7">


                Ansh Bomb - Chairperson ACM-CEG
                <br />
                <a href="tel:+911234567890">
                +919940342069
</a>

                <br />
                Shiyam- Vice Chairperson ACM-CEG
                
                
              <br />
              <a href="tel:+917305754799">
              +917305754799
              </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default Contact;


