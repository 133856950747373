const StarBorder = ({
    as: Component = "div",
    className = "",
    color = "white",
    speed = "6s",
    children,
    ...rest
  }) => {
    return (
      <Component className={`relative inline-block py-[1px] overflow-hidden rounded-[20px] ${className}`} {...rest}>
        <div
          className="absolute w-[300%] h-[50%] opacity-70 bottom-[-11px] right-[-250%] rounded-full animate-star-movement-bottom z-0"
          style={{
            background: `radial-gradient(circle, ${color}, transparent 10%)`,
            animationDuration: speed,
          }}
        ></div>
        <div
          className="absolute w-[300%] h-[50%] opacity-70 top-[-10px] left-[-250%] rounded-full animate-star-movement-top z-0"
          style={{
            background: `radial-gradient(circle, ${color}, transparent 10%)`,
            animationDuration: speed,
          }}
        ></div>
        <div className="relative z-1 bg-gradient-to-b from-black to-gray-900 border border-gray-800 text-white text-center text-[16px] py-[16px] px-[26px] rounded-[20px]">
          {children}
        </div>
      </Component>
    );
  };
  
  export default StarBorder;
  
  // tailwind.config.js
  // module.exports = {
  //   theme: {
  //     extend: {
  //       animation: {
  //         'star-movement-bottom': 'star-movement-bottom linear infinite alternate',
  //         'star-movement-top': 'star-movement-top linear infinite alternate',
  //       },
  //       keyframes: {
  //         'star-movement-bottom': {
  //           '0%': { transform: 'translate(0%, 0%)', opacity: '1' },
  //           '100%': { transform: 'translate(-100%, 0%)', opacity: '0' },
  //         },
  //         'star-movement-top': {
  //           '0%': { transform: 'translate(0%, 0%)', opacity: '1' },
  //           '100%': { transform: 'translate(100%, 0%)', opacity: '0' },
  //         },
  //       },
  //     },
  //   }
  // }