import React from "react";
// import img1 from "../assets/events/bc.png";
// import img2 from "../assets/events/gd.png";
// import img3 from "../assets/events/ama.png";
// import img4 from "../assets/events/ff.png";
// import img5 from "../assets/events/ide.png";
// import img6 from "../assets/events/sk.png";
// import img7 from "../assets/events/mat.png";
// import img8 from "../assets/events/quiz.png";
import bigdata from '../assets/big data.png'
import genai from '../assets/gen ai.png'
import supplychain from '../assets/supply-chain-management-vector.jpg'
import automation from '../assets/automation logo.png'
import gaming from '../assets/gaming for a cause.jpg'
import "../css/EventCard.css";

import SpotlightCard from "./SpotLightCard";
import ScrollFloat from './ScrollFloat';
import ScrollReveal from "./ScrollReveal";
const EventCard = () => {
  return (

    
    <div className="bg-gray-900">
      <h1 className="text-3xl font-bold text-center text-white pb-10" id="events">
      <ScrollFloat
  animationDuration={1}
  ease='back.inOut(2)'
  scrollStart='center bottom+=50%'
  scrollEnd='bottom bottom-=40%'
  stagger={0.03}
    className="text-10xl font-bold"
>
    Tracks
      
</ScrollFloat>
{/* <GradientText
  colors={["#40ffaa", "#4079ff", "#40ffaa", "#4079ff", "#40ffaa"]}
  animationSpeed={3}
  showBorder={false}
  animateOnView={true}
  className="custom-class"
>
Below is a description of the tracks and a few sample problem statements. These are purely for reference, and participants are encouraged to choose their own problem statement within a track.
</GradientText> */}



<div className="mt-2">
<ScrollReveal
  baseOpacity={0}
  enableBlur={true}
  baseRotation={5}
  blurStrength={10}
  
>
Below is a description of the tracks and a few sample problem statements. These are purely for reference, and participants are encouraged to choose their own problem statement within a track
  </ScrollReveal>
</div>

      </h1>
      <div className="flex justify-center items-center p-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
         
          <SpotlightCard spotlightColor="rgba(0, 229, 255, 0.2)">
            <div className="max-w-sm">
              <img class="mx-auto rounded-t-lg h-40 w-auto" src={gaming} alt="" />
              <div class="p-5">
                <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                  Gaming for a Cause
                </h5>
                <p class="mb-3 font-normal text-gray-400 text-justify">
                  Develop games that raise awareness or support social causes, integrating meaningful themes with engaging gameplay.
                  <br />
                  <label className="text-white"><br></br><strong>Sample Problem Statement(s):</strong></label>
                  <br /> 
                  <br></br>●	Develop a game to highlight and raise awareness regarding health issues that might still be stigmatized in today's society.
                  <br></br><br></br>●	The best way to understand someone is to put yourself in their shoes. Create a game to draw attention to the subtle and not-so-subtle ways in which women face discrimination. 
                </p>
              </div>
            </div>
          </SpotlightCard>

          <SpotlightCard spotlightColor="rgba(0, 229, 255, 0.2)">
            <div className="max-w-sm">
              <img class="mx-auto rounded-t-lg h-40 w-auto" src={supplychain} alt="" />
              <div class="p-5">
                <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                  <label><strong>Supply Chain Logistics</strong></label>
                </h5>
                <p class="mb-3 font-normal text-gray-400 text-justify">
                  Build tech-driven solutions to optimize inventory, transportation, and demand forecasting for efficient supply chain management across physical and digital products.
                  <br></br>
                  <label className="text-white"><br></br><strong>Sample Problem Statement(s):</strong></label>
                  <br />
                  <br></br>
                  ●	Heavily automated supply chains of consumable products and handicrafts have been criticized for their lack of sustainability and impact on sellers of hand-made goods and by extension, their cultural impact as a result
                  <br></br><br></br>●	According to the UN, period poverty refers to the inability to afford and access menstrual products, sanitation and hygiene facilities and education and awareness to manage menstrual health. The high cost of menstrual products, and lack of water and sanitation facilities play a major role. Design a robust supply chain solution to overcome this problem.
                  <br></br>
                  <br></br>
                </p>
              </div>
            </div>
          </SpotlightCard>

          <SpotlightCard spotlightColor="rgba(0, 229, 255, 0.2)">
            <div className="max-w-sm">
              <img class="mx-auto rounded-t-lg h-40 w-auto" src={bigdata} alt="" />
              <div class="p-5">
                <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                  Big Data Analytics 
                </h5>
                <p class="mb-3 font-normal text-gray-400 text-justify">
                  Design applications that extract actionable insights from large datasets, including but not limited to recommendation systems, social network analysis, and predictive modeling techniques to tackle complex data challenges.

                  <br /><label className="text-white"><br></br><strong>Sample Problem Statement(s):</strong></label>
                  <br /><br />●	Build a big data-powered recommendation engine using collaborative filtering and deep learning to analyze user behavior, purchase history, and browsing patterns for real-time personalized suggestions.
                </p>
              </div>
            </div>
          </SpotlightCard>

          
          <div className="md:col-span-3 flex flex-col md:flex-row justify-center w-full gap-4">
            <SpotlightCard spotlightColor="rgba(0, 229, 255, 0.2)">
              <div className="max-w-sm">
                <img class="mx-auto rounded-t-lg h-40 w-auto" src={automation} alt="" />
                <div class="p-5">
                  <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                    <label><strong>Automation</strong></label>
                  </h5>
                  <p class="mb-3 font-normal text-gray-400 text-justify">
                    Automation upgrades technology to streamline processes, eliminate repetitive tasks, and enhance productivity. It reduces human error. For example, in fintech, it powers seamless transactions, fraud detection, and personalized financial services. In agriculture, it enables precision farming, crop monitoring, and automated irrigation, driving sustainable growth.  
                      <br />
                    <label className="text-white"><br></br><strong>Sample Problem Statement(s):</strong></label>
                   <br /> <br></br>●	Infrastructural breakdown is a common problem in India, with identification being a manual process and reporting of issues requiring filing complaints in-person, an arduously long process.
                    <br></br><br></br>●	Women are often harassed in male-dominated online spaces such as gaming communities by means of unsolicited texts and images. Develop an efficient solution to automatically flag/identify and mitigate harassment of women online.
                    <br></br>
                    <br></br>
                  </p>
                </div>
              </div>
            </SpotlightCard>

            <SpotlightCard spotlightColor="rgba(0, 229, 255, 0.2)">
              <div className="max-w-sm">
                <img
                  class="mx-auto rounded-t-lg h-40 w-auto pt-2"
                  src={genai}
                  alt=""
                />
                <div class="p-5">
                  <h5 class="mb-2 text-xl font-bold tracking-tight text-white text-center">
                    GenAI for security/GenAI in Entertainment
                  </h5>
                  <p class="mb-3 font-normal text-gray-400 text-justify">
                    <strong>GenAI for security:</strong> Utilize generative AI to enhance security by detecting threats, automating incident response, and generating adaptive security policies.<br />
                    <strong>GenAI in Entertainment:</strong> Explore how GenAI can transform the way we produce and experience entertainment—from AI-generated scripts, music, and videos to immersive, personalized experiences.
                    <br />
                    <label className="text-white"><br></br><strong>Sample Problem Statement(s):</strong></label>
                    <br></br><br></br><strong>GenAI for Security:</strong>
                   <br /> ●	Develop a generative AI model that learns from security logs and threat intelligence, automatically detecting suspicious behavior, generating responss, and adapting firewall 'rules' dynamically.

                    <br /><br></br><strong>GenAI in Entertainment:</strong>
                    <br />●	Build a GenAI-powered platform that creates personalized stories, scripts, or dialogues based on user preferences, emotions, and past interactions. The system should adapt narratives in real-time to keep users engaged.
                    <br />●	Create a GenAI tool that composes original music or generates short video clips based on user inputs (e.g., mood, genre, or theme), enabling creators to produce content faster and more efficiently.

                    <br></br>
                    <br></br>
                  </p>
                </div>
              </div>
            </SpotlightCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;