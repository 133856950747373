import React from "react";
import ScrollFloat from "../components/ScrollFloat";
const PrizesSection = ({ img1, img2 }) => {
  return (
    <div className="bg-gray-900 py-10 text-center" id="prizes">
       
      <h1 className="text-5xl font-bold text-white mb-10">
      <ScrollFloat
  animationDuration={1}
  ease='back.inOut(2)'
  scrollStart='center bottom+=50%'
  scrollEnd='bottom bottom-=40%'
  stagger={0.03}
    className="text-10xl font-bold"
>
    Prizes
    </ScrollFloat>
      </h1>
     
      <div className="flex justify-center items-center gap-10">
        <img src={img1} alt="Prize 1" className="w-1/5 rounded-lg shadow-lg" />
        <img src={img2} alt="Prize 2" className="w-1/5 rounded-lg shadow-lg" />
      </div>
    </div>
  );
};

export default PrizesSection;