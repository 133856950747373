import React from "react";
import ScrollFloat from "../components/ScrollFloat";
import group from '../assets/WhatsApp Image 2025-03-31 at 03.07.05.jpeg'
const About = () => {
  return (
    <div className="bg-gray-900 p-5 text-center" id="about">
      <div className="p-6">
        <h3 className="mb-2 text-3xl font-bold tracking-tight text-white">
          <ScrollFloat
            animationDuration={1}
            ease='back.inOut(2)'
            scrollStart='center bottom+=50%'
            scrollEnd='bottom bottom-=40%'
            stagger={0.03}
            className="text-10xl font-bold"
          >
            About Us
          </ScrollFloat>
        </h3>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-auto max-w-screen-lg p-6 border rounded-lg shadow-lg bg-gray-800 border-gray-700 hover:bg-gray-700 transition-all">
          <div className="flex flex-col md:flex-row gap-6 items-center">
            <div className="text-left flex-1">
              <h5 className="mb-2 text-xl font-medium tracking-tight text-white">
                ACM CEG
              </h5>
              <p className="font-normal text-gray-400">
                The <b>ACM-CEG Student Chapter</b>, initiated in 2004, aims to instill an
                unwavering enthusiasm for computer science in students. The club
                provides a plethora of networking opportunities and helps to seek
                advice from the top experts in the field. The club has been steadily
                working to inculcate an unalloyed interest for Computer Science in
                students and consequently, stimulating the advancement of computer
                science as a whole. We aim to motivate students to embark on a
                journey of learning and developing the tools of computer science and
                instilling in them a passion for computer science. We serve to clear
                the clutter and provide guidance to help them to achieve their
                goals.
              </p>
            </div>
            <div className="flex-shrink-0">
              <img 
                src={group} 
                alt="ACM CEG" 
                className="rounded-lg w-full md:w-96 h-auto object-cover shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;