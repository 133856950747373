import React, { useState, useEffect } from "react";
import ACM_LOGO from "../assets/acm_logo.png";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";
import SmoothScroll from "smooth-scroll";

import Dock from "./Dock"; 
import { VscHome, VscArchive, VscAccount, VscSettingsGear } from "react-icons/vsc";
import { FaQuestionCircle } from "react-icons/fa";


const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {

    new SmoothScroll('a[href*="#"]', { speed: 700 });
  }, []);

  
  const scrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };


 
  const dockItems = [
    { icon: <VscHome className="text-white w-8 h-7" />, label: "Home", onClick: () => scrollToSection("#top") },
    { icon: <VscArchive className="text-white w-8 h-7" />, label: "Tracks", onClick: () => scrollToSection("#events") },
    { icon: <VscAccount className="text-white w-8 h-7" />, label: "About", onClick: () => scrollToSection("#about") },
    { icon: <VscSettingsGear className="text-white w-8 h-7" />, label: "Contact", onClick: () => scrollToSection("#contact") },
    { icon: <FaQuestionCircle className="text-white w-8 h-7" />, label: "FAQ", onClick: () => scrollToSection("#faq") }
  ];
  

  return (
    <div className="relative" id="top">
      
      <nav className="bg-gray-800 border-gray-700 py-2 fixed w-full top-0 z-50 shadow-md">
        <div className="max-w-screen-xl flex items-center justify-between mx-auto p-2">
          <a href="https://auceg.acm.org/" className="flex items-center">
            <img src={ACM_LOGO} className="h-10 mr-2" alt="ACM_CEG Logo" />
          </a>
          <button
            type="button"
            className="inline-flex items-center p-2 w-9 h-9 justify-center rounded-lg md:hidden focus:outline-none text-gray-400 hover:bg-gray-700 transition duration-300"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <GrFormClose className="w-5 h-5" /> : <HiOutlineMenuAlt3 className="w-5 h-5" />}
          </button>

          <div className={`${isMobileMenuOpen ? "block" : "hidden"} w-full md:flex md:w-auto`}>
            <ul className="flex flex-col font-medium md:flex-row md:space-x-6 bg-gray-800 md:bg-transparent">
              <li><a href="#top" className="py-1 px-2 text-white hover:text-blue-500">Home</a></li>
              <li><a href="#events" className="py-1 px-2 text-white hover:text-blue-500">Tracks</a></li>
              <li><a href="#prizes" className="py-1 px-2 text-white hover:text-blue-500">Prizes</a></li>
              <li><a href="#about" className="py-1 px-2 text-white hover:text-blue-500">About</a></li>
              <li><a href="#sponsor" className="py-1 px-2 text-white hover:text-blue-500">Sponsors</a></li>
              <li><a href="#contact" className="py-1 px-2 text-white hover:text-blue-500">Contact</a></li>
              <li><a href="#faq" className="py-1 px-2 text-white hover:text-blue-500">FAQ</a></li>
            </ul>
          </div>

        </div>
      </nav>

     
      <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 z-50">
        <Dock items={dockItems} panelHeight={60} baseItemSize={45} magnification={65} />
      </div>
    </div>

  );
};

export default Navbar;
