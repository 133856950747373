import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import SplitText from "../components/SplitText";
const FAQSection = () => {
  const [isOpen, setIsOpen] = useState(Array(10).fill(false));

  const toggleAccordion = (index) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };

  const handleAnimationComplete=()=>
  {
    console.log("Animation is Completed");
  }

  const faqData = [
    {

      question: "Is CODHER only for women?",
      answer:
        "Yes. It's a hackathon exclusive for women.",
    },
    {
      question: "What are the perks of attending CODHER'25?",
      answer: "An exciting 24 hour hackathon experience with interesting problems, the chance to interact with industry mentors who will help you shape your solutions better, Awards worth Rs. 70k+, Intern referrals to Motorq for the winners, exciting coupons from GrabON and many more.",
    },
    {
      question: "Any specific qualification to be a participant?",
      answer:
        "If you enjoy working on interesting problems and enjoy writing code, we are sure you’ll enjoy this. Everyone enrolled in an undergraduate programme is welcome to take part in the activity, regardless of their academic area or stream. We encourage beginners and freshman year students as well, you can browse through, learn and implement your ideas.",

    },
    // {
    //   question: "What is a rolling event?",
    //   answer:
    //     "A Rolling event can be attended at any time during the provided duration. All the mentioned events will be live during the duration mentioned and the students can participate one after the other. For example, if the event Coding Cauldron is scheduled between 10:00 a.m. - 1:00 pm and the time duration of the event is 45 minutes, participants can come anytime between 10 am and 12:15 pm and attempt the event. They will be given 45 mins from the time they start (e.g., if you come at 11 am, you must finish the event by 11:45 am).",
    // },
    {

      question: "How many participants are allowed per team?",
      answer:
        "Each team can consist of a maximum of 3 students. Individual participation is also allowed.",
    },
    {
      question:
        "Is there any registration fee to attend and how much per team?",
      answer:
        "Yes,The registration fee is Rs.180 per team.",
    },
    {
      question: "Will participants receive Certificates?",
      answer:
        "Yes, all the participants will receive participation certificates and prize winners will receive certificates along with other perks.",
    },
    {
      question: "Are inter-college teams allowed?",
      answer:
        "Yes, you can form teams with your other college friends as well.",
    },
    {
      question: "What is the mode of the hackathon and which platform is used?",
      answer:
        "The hackathon is completely online, and Microsoft Teams, WhatsApp, and Google Meet will be used.",
    },
    {
      question:
        "What is the duration of the hackathon?",
      answer:
        "The hackathon starts at 10 AM on April 5th (Saturday) and ends at 10 AM on April 6th (Sunday), following which final judging will begin. For more details about the flow, please refer to the \"Stages and Timeline \" section on the UnStop website listing of the hackathon.",
    },
    {
      question: "Is it an Open themed hackathon and when will the problem statements be released ?",
      answer:
        "There are five tracks, and students are allowed to pick any problem statement of their choice, on the condition that it falls under one of the five tracks mentioned in the tracks section of this website.",
    },
    {
      question: "Will there be review meets during the hackathon period?",
      answer:
        "Yes, there will be 2 review meets before the final submission which act like checkpoints to update progress and also get help from Mentors.",
    },
    {
      question: "Who are mentors and how will they help?",
      answer:
        "Mentors are industry experts who will review your work and help you with solutions if you are stuck with your implementation.",
    },
    {
      question: "What are the track prizes?",
      answer:
        "Apart from the Grand Champions, and the first and second runner up prizes, the best projects in each track will be awarded the track prize for that track. Apart from this, the Best First Year team, the Best Second Year team, and the Best UI team prizes are also up for grabs.",
    },
    {
      question: "Can the hackathon winners also win the track prizes?",
      answer:
        "No, the track prizes are awarded to the teams apart from the winners(top 3 teams).",
    },
    {
      question: "What is the Best First Year prize and the Best Second Year prize?",
      answer:
        "These are the track prizes awarded to the best Freshman(first years) and sophomore(second year) teams. To be eligible for the best First Year prize, all the team members should be in their first year and similarly for the Best Second year prize, all the members of the team should be in their second year.",
    },
    {
      question: "What are the Pre-events and who can participate in it?",
      answer:
        "Pre-events are aimed to create interest towards CODHER and anyone can participate in these pre-events. There are 2 pre-events for CODHER'25, Think-a-Thon and Hot Sauce Talks.",
    },
    {
      question: "How do we submit our work for the hackathon?",
      answer:
        "Participants are expected to upload their work to GitHub and submit the GitHub repository link + their presentation (PDF/PPT) on UnStop when the round is live in the \"Stages and Timelines\" section on day of the Hackathon.",
    },
    {
      question: "Are there any mandatory requirements?",
      answer:
        "All participants are required to install and set up Microsoft Teams for the inaugural ceremony on April 5th at 9:00 AM. Subsequent communication will happen via WhatsApp and Google Meets.",

    },
    {
      question:"Are there any other rules/guidelines?",
      answer:"For any further information regarding guidelines, submission criteria, round details, etc of CodHer'25, participants are requested to go through the event listing at:https://unstop.com/hackathons/codher25-anna-university-chennai-1432151",
    }
  ];

  return (
    <div className="bg-gray-900" id="faq">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-white">
          
        <SplitText
  text="Frequently Asked Questions"
  className="text-5xl font-extrabold text-center text-white mt-10" 
  delay={150}
  animationFrom={{ opacity: 0, transform: 'translate3d(0,50px,0)' }}
  animationTo={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
  easing="easeOutCubic"
  threshold={0.2}
  rootMargin="-50px"
  onLetterAnimationComplete={handleAnimationComplete}
/>

        </h2>
        <div className="grid pt-8 text-left border-t md:gap-16 border-gray-700 md:grid-cols-2">
          <div>
            {faqData
              .slice(0, Math.ceil(faqData.length / 2))
              .map((faq, index) => (
                <div key={index} className="mb-4">
                  <h3 className="flex items-center mb-4 text-lg font-medium text-white">
                    <button
                      className="focus:outline-none"
                      onClick={() => toggleAccordion(index)}
                    >
                      {isOpen[index] ? (
                        <IoIosArrowUp className="w-5 h-5 text-gray-400 mr-2" />
                      ) : (
                        <IoIosArrowDown className="w-5 h-5 text-gray-400 mr-2" />
                      )}
                    </button>
                    {faq.question}
                  </h3>
                  <div
                    className={`text-gray-400 transition-height ease-in-out ${
                      isOpen[index] ? "max-h-full" : "max-h-0 overflow-hidden"
                    }`}
                  >
                    {faq.answer.includes("You will be added to WhatsApp groups before the event day") ? (
                      <>
                        <p>Arunachalam - 95515 40001</p>
                        <p>Rishitha - 90435 02502</p>
                        <p>Ashwin Muthuraman - 93605 23558</p>
                        <p>Shivaani - 94442 57125</p>
                      </>
                    ) : (
                      <p>{faq.answer}</p>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div>
            {faqData.slice(Math.ceil(faqData.length / 2)).map((faq, index) => (
              <div key={index} className="mb-4">
                <h3 className="flex items-center mb-4 text-lg font-medium text-white">
                  <button
                    className="focus:outline-none"
                    onClick={() =>
                      toggleAccordion(index + Math.ceil(faqData.length / 2))
                    }
                  >
                    {isOpen[index + Math.ceil(faqData.length / 2)] ? (
                      <IoIosArrowUp className="w-5 h-5 text-gray-400 mr-2" />
                    ) : (
                      <IoIosArrowDown className="w-5 h-5text-gray-400 mr-2" />
                    )}
                  </button>
                  {faq.question}
                </h3>
                <div
                  className={`text-gray-400 transition-height ease-in-out ${
                    isOpen[index + Math.ceil(faqData.length / 2)]
                      ? "max-h-full"
                      : "max-h-0 overflow-hidden"
                  }`}
                >
                  {faq.answer.includes("You will be added to WhatsApp groups before the event day") ? (
                    <>
                      <p>You will be added to WhatsApp groups before the event day. If you have any other concerns/doubts, you can contact the following numbers:</p>
                      <p>Arunachalam - 95515 40001</p>
                      <p>Rishitha - 90435 02502</p>
                      <p>Ashwin Muthuraman - 93605 23558</p>
                      <p>Shivaani - 94442 57125</p>
                    </>
                  ) : (
                    <p>{faq.answer}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;