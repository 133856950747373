import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import EventCard from "../components/EventCard";
import About from "./About";
import CountdownTimer from "../components/CountdownTimer";
// import Gallery from "./Gallery";
import Contact from "./Contact";
import Faq from "./Faq"
import prize1 from "../assets/codher_prize_post1.jpg"
import prize2 from "../assets/codher_prize_post2.jpg"
import StarBorder from "../components/StarBorder";
import SplitText from "../components/SplitText";
import codherLogo from '../assets/codher_logo_no_bg.svg'
// import prodigy_logo from "../assets/prodigy_logo.png";
import Sponsors from "./Sponsor";
import unstop from '../assets/unstop.jpg'
import motorq from '../assets/motorq.png'
import PrizesSection from "./PrizeSection";

const handleAnimationComplete=()=>
{
  console.log("Completed");
}
const Home = () => {
  const targetDate = new Date("2024-01-06T00:00:00").getTime();


  return (
    <div className="bg-gray-900">
      <Navbar />
      <div class="bg-gray-900 mb-3"></div>
      <section class="bg-gray-900">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">


            {/* <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
              CodHer
            </h1> */}
  <br />
  <br />
<SplitText
  text="Welcome to CodHer!"
  className="text-5xl font-extrabold text-center text-white mt-10" 
  delay={150}
  animationFrom={{ opacity: 0, transform: 'translate3d(0,50px,0)' }}
  animationTo={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
  easing="easeOutCubic"
  threshold={0.2}
  rootMargin="-50px"
  onLetterAnimationComplete={handleAnimationComplete}
/>

            <p class="text-justify max-w-2xl mt-4 mb-6 font-light lg:mb-8 md:text-lg lg:text-xl text-gray-400 ">

          An all day hackathon to channel all that inner coddess energy to give shape to your amazing ideas.
           Team up with fellow queen bees for an awesome hackathon experience, complete with mentors who'll
            provide you with all the guidance you need. Hack from the comfort of your homes/hostel rooms as
             it's going to be online this time. Join us to hack and slay, one line of code at a time.
            </p>
            <p class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4  focus:ring-gray-800">
              {/* Register */}



              {/* <svg
                class="w-5 h-5 ml-2 -mr-1 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                 <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path> 
              </svg> */}
            </p>
        
                
            <StarBorder as="a"
  href="https://unstop.com/hackathons/codher25-anna-university-chennai-1432151" 
  target="_blank" 
  rel="noreferrer" 
  className="mr-20 text-base font-medium text-center text-white border rounded-lg border-gray-700 hover:bg-gray-700 focus:ring-4 focus:ring-gray-800"
  color="cyan" speed="5s"
>
  Register
</StarBorder>

          
            {/* <a
              href="https://forms.gle/3bYJa81BHozMr6dSA"


              target="_blank"
              rel="noreferrer"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg  focus:ring-4  text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800"
            >


              Students */}
            {/* </a> */}

          </div>
          <div class=" lg:mt-0 lg:col-span-5 flex justify-center items-center mt-8">
            <img src={codherLogo} alt="mockup" />

          </div>
        </div>
      </section>
      <CountdownTimer targetDate={targetDate} />
      <EventCard />
      <PrizesSection img1={prize1} img2={prize2}/>
      <About />

      {/* <Gallery /> */}
      <Sponsors/>
      <Contact />
      <Faq />


      <Footer />
    </div>
  );
};

export default Home;
